.maintenance {
  border-radius: 10%;
  padding: 0.3rem;
  border: 1px solid var(--primary-black) !important;
  text-align: center;
}

.nav.nav-tabs {
    text-transform: capitalize;
}
