@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
/**/
:root {
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-orange: #f9ab00;
  --primary-dark: rgb(52, 58, 64);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-black: #000000;
  --primary-transparent-black: rgba(0, 0, 0, 0.7);
  --primary-green: green;
}

main {
  min-height: 80vh;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

h5 {
  font-size: 1rem;
  padding: 0.5rem 0;
}

.rating span {
  margin: 0.1rem;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}

.carousel-caption {
  position: relative;
  top: 0;
  left: 0;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  height: 300px;
  padding: 5%;
  margin: auto;
  border-radius: 30%;
}

.carousel a {
  margin: 0 auto;
}

.backgroundimg {
  background-image: '';
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}

.sticky-nav {
  position: sticky;
  top: 0;
}

/************************ SERVICES *********************************/
.services {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
}

.services h1 {
  color: var(--primary-dark);
  text-transform: uppercase;
}

.services .box {
  height: auto;
  border-bottom: 0.3125rem solid var(--primary-black);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
}

/************************ BLOGS *********************************/
.blogs {
  text-align: left;
  padding-bottom: 2rem;
}

.blogs h1 {
  color: var(--primary-dark);
  text-transform: uppercase;
}

.blogs .box {
  height: auto;
  border-bottom: 0.3125rem solid var(--primary-green);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
}

/************************ SOCIAL FOLLOW ****************************/
.social-container {
  background: var(--primary-white);
  padding: 25px 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: black;
}

/************************ FOOTER COMPONENT *********************************/
.footer {
  background: var(--primary-black);
  color: var(--primary-white);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-white);
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-orange);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}

/******************************* TEXT EDITOR  **********************************************/
.rdw-editor-main {
  min-height: 300px;
  border: 1px solid var(--primary-light-grey);
}

/************************ CONTACTS COMPONENT *********************************/
.contacts {
  background: var(--primary-grey);
  padding: 3rem 0;
}

.contacts h1 {
  color: var(--primary-black);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: var(--primary-black);
  font-size: 1.2rem;
}

/*------------------ INPUTS -------------------------------*/
.contacts input,
.contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-black);
  border-radius: 0;
  color: var(--primary-black);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus,
.contacts textarea:focus {
  background: transparent;
  color: var(--primary-black);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.125rem;
  background: var(--primary-orange);
  display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--primary-white);
  background: var(--primary-hover-red);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: var(--primary-green);
}

/*---------------- MEDIA -----------------------*/
@media (max-width: 768px) {
  .contacts {
    overflow-x: hidden;
  }

  .contacts .container {
    padding: 0 1rem;
  }

  .contacts p {
    padding: 0 0.4rem;
  }
}
